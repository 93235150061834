import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { HomeworkModel } from "@/models";
import { Common } from "../Common";

export class SearchForm {
    active = '';
    grade = '';
    cid = '';
    sid = '';
    clid = '';
    tid = '';
    student = '';
    date = '';
    name = '';
}

type response = { [key: string]: unknown };

@Component<AdminHomeworkVerifyController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminHomeworkVerifyController extends Vue {
    private searchForm = new SearchForm();
    private cityList: { [key: string]: any} = {};
    private schoolList: { [key: string]: any} = {};
    private classList: object = {};
    private teacherList: object = {};
    private resultList: object = {};
    private gradeList: object = {};
    private num = 0;
    private pageHtml = "";

    private identity = window.localStorage.getItem('admin_identity') as string;
    private sid = window.localStorage.getItem('admin_sid') as string;
    private cid = '';
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;

    public async created() {
        if (this.identity == "1") {
            this.cityList = await Common.getSchoolCity(); //取得有分校之縣市
        } else {
            this.cityList = await Common.getCity(this.sid); //取得該分校之縣市
            this.cid = this.cityList[0].cid;
            this.searchForm.cid = this.cid;

            const school = window.localStorage.getItem('admin_school') as string;
            this.schoolList[0] = {
                sid: this.sid,
                name: school,
            };
            this.searchForm.sid = this.sid;
            this.getClass(this.sid);
        }
        this.getResultList();
        this.gradeList = await Common.getGrade();
    }

    // 取得 該縣市的分校列表 
    private async getSchool(cid: string) {
        if (this.identity == "1") {
            this.searchForm.sid = '';
            this.searchForm.clid = '';
            this.searchForm.tid = '';
            
            this.schoolList = await Common.getSchool(cid);
            this.classList = [];
            this.teacherList = [];
        }
    }

    // 取得 該分校的班級列表
    private async getClass(sid: string) {
        this.searchForm.clid = '';
        this.searchForm.tid = '';

        this.classList = await Common.getClass(2, sid, 0);
        this.teacherList = [];
    }

    // 取得 該班級的老師列表
    private async getTeacher(clid: string) {
        this.searchForm.tid = '';
        this.teacherList = await Common.getTeacher(2, clid, 0);
    }

    private async getResultList() {
        if (this.identity == '2' && (this.searchForm.cid == '' || this.searchForm.sid == '')) {
            this.searchForm.cid = this.cid;
            this.searchForm.sid = this.sid;
            this.getClass(this.sid);
        }
        const item: { [key: string]: string } = {
            page: this.$route.query.page as string,
            active: this.searchForm.active,
            grade: this.searchForm.grade,
            cid: this.searchForm.cid,
            sid: this.searchForm.sid,
            clid: this.searchForm.clid,
            tid: this.searchForm.tid,
            student: this.searchForm.student,
            date: this.searchForm.date,
            name: this.searchForm.name,
            token: this.token,
        }
        const data = await HomeworkModel.getResultList(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.resultList = data.list as object;
        this.pageHtml = data.pageHtml as string;
    }
    
    public async exportList() {
        if (this.identity == '2' && (this.searchForm.cid == '' || this.searchForm.sid == '')) {
            this.searchForm.cid = this.cid;
            this.searchForm.sid = this.sid;
            this.getClass(this.sid);
        }
        const item: { [key: string]: string } = {
            page: this.$route.query.page as string,
            active: this.searchForm.active,
            grade: this.searchForm.grade,
            cid: this.searchForm.cid,
            sid: this.searchForm.sid,
            clid: this.searchForm.clid,
            tid: this.searchForm.tid,
            student: this.searchForm.student,
            date: this.searchForm.date,
            name: this.searchForm.name,
            token: this.token,
        }
        const filepath = await HomeworkModel.exportList(item);
        window.location.href = filepath;
    }

    private closeLB() {
        this.openLB = false;
    }
}
